// app/core/auth/access-claim.util.ts

import { RoleBasedPermission, Roles } from '@src/app/shared/directives/access.map';
import * as jwt from 'jsonwebtoken';
import { environment } from '../../../environments/environment';

// eslint-disable-next-line @typescript-eslint/naming-convention
const GROUP_FLAG_LENGTH = 6;

export const getIndividualIdFromGroupId = (groupId: string): string =>
  groupId.substring(0, groupId.length - GROUP_FLAG_LENGTH);

export interface JWTClaims {
  [key: string]: string;
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function getAccessClaims(token: string): JWTClaims {
  const decoded = jwt.decode(token, { complete: true });
  const payload = decoded?.payload as jwt.JwtPayload;
  return  payload?.['custom:accessClaims'] as string ? JSON.parse(payload?.['custom:accessClaims'] as string) as JWTClaims : null;
}

export const isOpsDev = (claims: JWTClaims): Boolean => claims?.[`${environment.MAIN_ENV}`] === Roles.OPS_DEV;

export const canAccessBureau = (claim: Roles): Boolean => [Roles.DEV_OWNER, Roles.DEV_ADMIN].includes(claim);

export const canSelectBureau = (claim: string): Boolean => Roles.OPS_DEV === claim;
