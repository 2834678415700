// app/core/auth/auth.service.ts

import { Injectable, NgZone } from '@angular/core';
import { Store } from '@ngrx/store';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Hub } from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';
import { Router } from '@angular/router';
import { getBureausForOpsDev, loginSuccess, setAuthenticatedToFalse } from './auth.actions';
import { getAccessClaims, isOpsDev } from './access-claim.util';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  constructor(private store: Store, private zone: NgZone, private router: Router) {
    // Get the user on creation of this service
    // TODO delete?
    Auth.currentAuthenticatedUser().then(
      (cognitoUser: CognitoUser) => {

        Auth.getPreferredMFA(cognitoUser).then((mfaPrefs) => {
          const isMFASetup = mfaPrefs !== 'NOMFA';

          // check to see if ops dev
          cognitoUser.getSignInUserSession().getAccessToken();
          cognitoUser.getSignInUserSession().getIdToken();

          const jwt = cognitoUser.getSignInUserSession().getIdToken().getJwtToken();

          const claims = getAccessClaims(jwt);

          this.store.dispatch(loginSuccess({ cognitoUserSession: cognitoUser.getSignInUserSession(), isMFASetup }));

          if (isOpsDev(claims)) {
            this.store.dispatch(getBureausForOpsDev({ jwt }));
            this.router.navigate(['/authenticate/bureau-selection']);
          }

        }, err => this.store.dispatch(setAuthenticatedToFalse()));

      },
      err => this.store.dispatch(setAuthenticatedToFalse()),
    );

    // Use Hub channel 'auth' to get notified on changes
    Hub.listen('auth', ({ payload: { event, data, message } }) => {
      if (event === 'signOut') {
        this.store.dispatch(setAuthenticatedToFalse());
      }
    });
  }

}
