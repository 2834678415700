// app/modules/bureau-profile/bureau.service.ts

import { Injectable } from '@angular/core';
import { v1 as ApiDopsV1 } from '@xform/api-dops';
import { v1 as ApiFops } from '@xform/api-fops';
import { Observable } from 'rxjs';
import { XPSMainHttpService } from '../../shared/services/xps-main-http.service';

@Injectable()
export class BureauService {
  constructor(
    private http: XPSMainHttpService,
  ) {}

  getBureau(bureauId: string): Observable<ApiDopsV1.BureauView> {
    return this.http.get<ApiDopsV1.BureauView>(`/api-dops/v1/view/bureau/${bureauId}`);
  }

  getBureauList(): Observable<ApiFops.BureauList> {
    const at = encodeURIComponent(new Date().toISOString());
    return this.http.get(`/api-fops/v1/view/bureau?at=${at}`);
  }
}