// app/modules/app-shell/header/header.component.ts

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Auth } from '@aws-amplify/auth';
import { Store } from '@ngrx/store';
import { UIOption } from '@xform/xformative-angular';
import { Subscription } from 'rxjs/internal/Subscription';
import { map, withLatestFrom } from 'rxjs/operators';
import { updateAccessClaim, updateEnvironment } from '@app/core/auth/auth.actions';
import { getAccessClaims, isOpsDev, JWTClaims } from '@src/app/core/auth/access-claim.util';
import { selectBureau, selectJWT, selectUserProfile } from '../../../core/auth/auth.selectors';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {

  @Input() isScreenSmall: boolean;

  @Output() public sidenavToggle = new EventEmitter();

  environmentOptions: UIOption[] = [];

  watcher: Subscription;

  claims: JWTClaims;

  userProfile$ = this.store.select(selectUserProfile);

  // get bureauIds
  // bureauIds$ = this.store.select(selectBureauIds);

  selected$ = this.store.select(selectJWT).pipe(
    withLatestFrom(this.store.select(selectBureau)),
    map(([jwt, selectedBureau]) => {
      this.claims = getAccessClaims(jwt);

      if (this.claims) {
        Object.entries(this.claims).forEach(claim => {
          if (!this.environmentOptions.find(option => option.value === claim[0])) {
            this.environmentOptions.push({
              displayLabel: `${claim[0].toUpperCase()}`,
              value: { env: claim[0], access: claim[1] },
            });
          }
        });

        if (isOpsDev(this.claims)) {
          // filter environment options with selected bureau

          const envNames = selectedBureau?.profile?.envs.map((env) => env.name);
          this.environmentOptions = this.environmentOptions.filter((env) => envNames.includes(env.displayLabel.toLowerCase()));
        }
      }

      if (this.environmentOptions.length) {
        this.store.dispatch(updateEnvironment({ instance: this.environmentOptions[0]?.value?.env }));
        this.store.dispatch(updateAccessClaim({ environment: this.environmentOptions[0]?.value?.env, access: this.environmentOptions[0]?.value.access }));
      }
      return this.environmentOptions;
    }),
  );

  constructor(
    private store: Store,
  ) {
  }

  public onToggleSidenav(): void {
    this.sidenavToggle.emit();
  }

  signOut(): void {
    Auth.signOut();
  }

  routeToProfile(): void {
    location.href = '/user-profile';
  }

  environmentChange(accessClaim: { env: string, access: string }): void {
    this.store.dispatch(updateAccessClaim({ environment: accessClaim.env, access: accessClaim.access }));
  }
}
