// app/shared/directives/directives.module.ts

import { NgModule } from '@angular/core';
import { ClaimsDisableDirective } from './claim-disable.directive';
import { IfAccessDirective } from './if-access.directive';

@NgModule({
  imports: [],
  declarations: [ClaimsDisableDirective, IfAccessDirective],
  exports: [ClaimsDisableDirective, IfAccessDirective],
})
export class DirectivesModule { }
