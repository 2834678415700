// app/modules/shared/shared.module.ts

import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  arrowLeft,
  arrowRight,
  check,
  chevronLeft,
  chevronRight,
  creditCard,
  HeroIconsModule,
  reply,
  rewind,
  x,
} from 'ng-heroicons';
import { NgxMaskModule } from 'ngx-mask';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { SharedModule as  XFAngularModule } from '@xform/xformative-angular';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { DirectivesModule } from '../../shared/directives/directives.module';
import { ChartComponent } from './chart/chart.component';
import { IconsModule } from './icons/icons.module';
import { PhoneNumberBoxComponent } from './form-components/phone-number-box/phone-number-box/phone-number-box.component';

@NgModule({
  declarations: [
    PhoneNumberBoxComponent,
    ChartComponent,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    // TODO delete FormsModule and refactor checkbox-group
    FormsModule,
    ReactiveFormsModule,
    NgxJsonViewerModule,
    DirectivesModule,
    NgxIntlTelInputModule,
    HeroIconsModule.withIcons({
      arrowLeft,
      arrowRight,
      check,
      chevronLeft,
      chevronRight,
      creditCard,
      reply,
      rewind,
      x,
    }),
    // MaterialModule,
    NgxMaskModule.forRoot(),
    RouterModule,
    IconsModule,
    XFAngularModule,
  ],
  exports: [
    ChartComponent,
    NgxMaskModule,
    FlexLayoutModule,
    // MaterialModule,
    IconsModule,
    ReactiveFormsModule,
    XFAngularModule,
    DirectivesModule,
    PhoneNumberBoxComponent,
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],

})
export class SharedModule {
}
