// app/modules/app-shell/app-shell.module.ts

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { QRCodeModule } from 'angularx-qrcode';
import { HeroIconsModule, menu, userSolid } from 'ng-heroicons';
import { SharedModule } from '../shared/shared.module';
import { AppShellRoutingModule } from './app-shell.routing';
import { ContainerComponent } from './container/container.component';
import { HeaderComponent } from './header/header.component';
import { SidenavComponent } from './sidenav/sidenav.component';

@NgModule({
  declarations: [
    ContainerComponent,
    HeaderComponent,
    SidenavComponent,
  ],
  imports: [
    AppShellRoutingModule,
    BrowserModule,
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    QRCodeModule,
    SharedModule,
    HeroIconsModule.withIcons({
      menu,
      userSolid,
    }),
  ],
  exports: [
    ContainerComponent,
  ],
  providers: [
  ],
})
export class AppShellModule { }
