// app/modules/shared/utils/utils.ts

import { Params, RouterStateSnapshot } from '@angular/router';
import {createFeatureSelector} from '@ngrx/store';
import { Injectable } from '@angular/core';
import { RouterReducerState } from '@ngrx/router-store/src/reducer';
import { RouterStateSerializer } from '@ngrx/router-store/src/serializers/base';
import * as jwt from 'jsonwebtoken';
import { environment } from '@src/environments/environment';

export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
}

export const getRouterState = createFeatureSelector<RouterReducerState<RouterStateUrl>>('router');

@Injectable()
export class CustomRouterStateSerializer implements RouterStateSerializer<RouterStateUrl> {
  public serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    let route = routerState.root;

    while (route.firstChild) {
      route = route.firstChild;
    }

    const { url, root: { queryParams } } = routerState;
    const { params } = route;

    // Only return an object including the URL, params and query params
    // instead of the entire snapshot
    return { url, params, queryParams };
  }
}

export const daysPriorToToday = (numberOfDays: number): Date => {
  const date = new Date();
  date.setDate(date.getDate() - numberOfDays);
  return date;
};

export const daysPriorOfDate = (daysPrior: number, date: Date): Date => {
  const dateTime = new Date(date);
  dateTime.setDate(date.getDate() - daysPrior);
  return dateTime;
};

export const oneWeek = 7;

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function getEnvBaseUrl(route: string): string {
  let baseUrl: string;
  const programApi: Boolean = route.includes('api-program');
  const simulatorApi: Boolean = route.includes('api-simulator');
  const ledgerApi: Boolean = route.includes('api-ledger');
  const apiFops: Boolean = route.includes('api-fops');
  const apiReport: Boolean = route.includes('api-report');

  if (programApi) {
    baseUrl = environment.API_PROGRAM_SERVICE_URL;
  } else if (simulatorApi) {
    baseUrl = environment.API_SIMULATOR_SERVICE_URL;
  } else if (ledgerApi) {
    baseUrl = environment.API_LEDGER_SERVICE_URL;
  } else if (apiFops) {
    baseUrl = environment.API_FOPS_SERVICE_URL;
  } else if (apiReport) {
    baseUrl = environment.API_REPORT_SERVICE_URL;
  } else {
    baseUrl = environment.API_DOPS_SERVICE_URL;
  }
  return baseUrl;
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function getCustomBaseUrl(route: string, env: string): string {
  let baseUrl: string;
  const programApi: Boolean = route.includes('api-program');
  const simulatorApi: Boolean = route.includes('api-simulator');
  const ledgerApi: Boolean = route.includes('api-ledger');
  const apiFops: Boolean = route.includes('api-fops');
  const apiReport: Boolean = route.includes('api-report');

  const determinedAddition = `${env}.${environment.HOSTED_ZONE}`;

  if (programApi) {
    baseUrl = `https://api-program${determinedAddition}`;
  } else if (simulatorApi) {
    baseUrl = `https://api-simulator${determinedAddition}`;
  } else if (ledgerApi) {
    baseUrl = `https://api-ledger${determinedAddition}`;
  } else if (apiFops) {
    baseUrl = `https://api-fops${determinedAddition}`;
  } else if (apiReport) {
    baseUrl = `https://api-report${determinedAddition}`;
  } else {
    baseUrl = `https://api-dops${determinedAddition}`;
  }
  return baseUrl;
}
