// environments/environment.dev.ts

export const environment = {
  production: false,

  authIdentityPoolId: 'us-east-1:698cf4f6-facd-461b-8ffd-bdb1e511f899',
  authRegion: 'us-east-1',
  authUserPoolId: 'us-east-1_WrRUCJ08x',
  authUserPoolWebClientId: '7qvi4b677rlovv56c17a7j1psa',
  authenticationFlowType: 'USER_SRP_AUTH',

  oauthDomain: 'xformative-aviato-dev.auth.us-east-1.amazoncognito.com',
  oauthScope: ['openid', 'aws.cognito.signin.user.admin'],
  oauthRedirectSignIn: 'https://d3aq2xy1fs3kz2.cloudfront.net/login',
  oauthRedirectSignOut: 'https://d3aq2xy1fs3kz2.cloudfront.net',
  oauthResponseType: 'token',

  API_CARD_SERVICE_URL: 'https://0bkvmp1cik.execute-api.us-east-1.amazonaws.com/xform-dev',
  API_DOPS_SERVICE_URL: 'https://n6o8hgf56g.execute-api.us-east-1.amazonaws.com/xform-dev',
  API_LEDGER_SERVICE_URL: 'https://g1t8e2hovk.execute-api.us-east-1.amazonaws.com/xform-dev',
  API_ORGANIZATION_SERVICE_URL: 'https://t4ijba15v6.execute-api.us-east-1.amazonaws.com/xform-dev',
  API_PAYMENT_SERVICE_URL: 'https://304hdavi1k.execute-api.us-east-1.amazonaws.com/xform-dev',
  API_PROGRAM_SERVICE_URL: 'https://o0fgzxu6ka.execute-api.us-east-1.amazonaws.com/xform-dev',
  API_SIMULATOR_SERVICE_URL: 'https://46lv56t7s8.execute-api.us-east-1.amazonaws.com/xform-dev',
  API_REPORT_SERVICE_URL: 'https://jt5xbdzsp1.execute-api.us-east-1.amazonaws.com/xform-dev',
  API_FOPS_SERVICE_URL: 'https://pmxk7uc9x2.execute-api.us-east-1.amazonaws.com/xform-dev',
  API_CLIENT_SERVICE_URL: 'https://zxggk9ifu4.execute-api.us-east-1.amazonaws.com/xform-dev',
  API_INDIVIDUAL_SERVICE_URL: 'https://wb615f7pjk.execute-api.us-east-1.amazonaws.com/xform-dev',

  IS_LOCAL: false,
  URL_SPECIAL_HANDLING: false,
  HOSTED_ZONE: 'xformative.cloud',
  MAIN_ENV: 'xform-dev',
}
