// app/app.component.ts

import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'uba-dops';

  constructor(
    private router: Router,
  ) { }

  ngOnInit():void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationStart),
    ).subscribe({
      next: (event: NavigationStart) => {
        if (event.url === '/') {
          this.router.navigate(['/dashboard']);
        }
      },
    });  
  }
}
