// app/core/auth/error.interceptor.ts

/* eslint-disable no-magic-numbers */
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Auth } from '@aws-amplify/auth';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export interface ApplicationError {
  message?: string;
}

export enum HttpStatusCode {
  Continue = 100,
  SwitchingProtocols = 101,
  Processing = 102,
  EarlyHints = 103,
  Ok = 200,
  Created = 201,
  Accepted = 202,
  NonAuthoritativeInformation = 203,
  NoContent = 204,
  ResetContent = 205,
  PartialContent = 206,
  MultiStatus = 207,
  AlreadyReported = 208,
  ImUsed = 226,
  MultipleChoices = 300,
  MovedPermanently = 301,
  Found = 302,
  SeeOther = 303,
  NotModified = 304,
  UseProxy = 305,
  Unused = 306,
  TemporaryRedirect = 307,
  PermanentRedirect = 308,
  BadRequest = 400,
  Unauthorized = 401,
  PaymentRequired = 402,
  Forbidden = 403,
  NotFound = 404,
  MethodNotAllowed = 405,
  NotAcceptable = 406,
  ProxyAuthenticationRequired = 407,
  RequestTimeout = 408,
  Conflict = 409,
  Gone = 410,
  LengthRequired = 411,
  PreconditionFailed = 412,
  PayloadTooLarge = 413,
  UriTooLong = 414,
  UnsupportedMediaType = 415,
  RangeNotSatisfiable = 416,
  ExpectationFailed = 417,
  ImATeapot = 418,
  MisdirectedRequest = 421,
  UnprocessableEntity = 422,
  Locked = 423,
  FailedDependency = 424,
  TooEarly = 425,
  UpgradeRequired = 426,
  PreconditionRequired = 428,
  TooManyRequests = 429,
  RequestHeaderFieldsTooLarge = 431,
  UnavailableForLegalReasons = 451,
  InternalServerError = 500,
  NotImplemented = 501,
  BadGateway = 502,
  ServiceUnavailable = 503,
  GatewayTimeout = 504,
  HttpVersionNotSupported = 505,
  VariantAlsoNegotiates = 506,
  InsufficientStorage = 507,
  LoopDetected = 508,
  NotExtended = 510,
  NetworkAuthenticationRequired = 511,
}

/**
 * This is used to logout the user, when the server responds with an unathorized status code.
 * Especially when the session token expires.
 *
 * @export
 * @class ErrorInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  errorMap = {
    [HttpStatusCode.BadRequest]: 'Bad Request',
    [HttpStatusCode.Unauthorized]: 'Invalid Credentials - Logging Out',
    [HttpStatusCode.NotFound]: 'Not Found',
    [HttpStatusCode.Conflict]: 'Duplicate Request - Skipping',
    [HttpStatusCode.InternalServerError]: 'Internal Server Error',
  };

  constructor() { }

  /**
   * Intercepter intercepts the responses, and then process based on the recieved status code
   * Read more : http://jasonwatmore.com/post/2018/05/23/angular-6-jwt-authentication-example-tutorial
   *
   * @param request
   * @param next
   * @returns
   * @memberof ErrorInterceptor
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {

      let appError: ApplicationError;

      if (err.status === HttpStatusCode.Unauthorized) {
        // auto logout if 401 response returned from api
        Auth.signOut({ global: true })
          .then(data => console.log(data))
          .catch(error => console.log(error));
      }

      if (err.status === HttpStatusCode.BadRequest) {
        appError = {
          message: err?.error?.message || err?.error || this.errorMap[err.status],
        };

      }

      if (!appError) {
        appError = { message: this.errorMap[err.status] || err.message || err.statusText };
      }

      return throwError(appError);
    }));
  }
}
