// app/shared/services/xps-http.service.ts
/* eslint-disable max-classes-per-file */
// tslint:disable:no-identical-functions

// import { IncomingMessage } from 'http';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Auth } from '@aws-amplify/auth';
import { Store } from '@ngrx/store';
import { selectEnvironment } from '@src/app/core/auth/auth.selectors';
import { getCustomBaseUrl, getEnvBaseUrl } from '@src/app/modules/shared/utils/utils';
import { defer, Observable } from 'rxjs';
import { first } from 'rxjs/internal/operators/first';
import { environment } from '../../../environments/environment';

const shortId = () => {
  let text = '';
  // eslint-disable-next-line no-secrets/no-secrets
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  // eslint-disable-next-line no-magic-numbers
  const length = 8;

  // eslint-disable-next-line id-length
  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
};

const context = {
  get: () => shortId(),
};

export class HttpParams {
  private params: { [key: string]: string };

  constructor(paramsBuilder?: { fromObject: { [key: string]: string } }) {
    if (typeof paramsBuilder !== 'undefined') {
      this.params = paramsBuilder.fromObject;
    } else {
      this.params = {};
    }
  }

  public generate(): string {
    return Object.entries(this.params).reduce((accumulator, current, index) => {
      const [param, value] = current;
      let result = '';
      if (index > 0) {
        result += `${accumulator}&`;
      } else {
        result += accumulator;
      }
      result += `${param}=${value}`;
      return result;
    }, '');
  }

  public append(param: string, value: string): void {
    this.params[param] = value;
  }

  public set(param: string, value: string): this {
    this.params[param] = value;
    return this;
  }

  public get(): { [key: string]: string } {
    return this.params;
  }
}

export interface Options {
  json?: object;
  params?: HttpParams;
  headers?: {
    [header: string]: string;
  };
  resolveWithFullResponse?: boolean;
}

export interface FullResponse {
  statusCode: number;
  statusMessage: string;
  body: any;
}

type Methods = 'get' | 'put' | 'post' | 'del';

@Injectable()
export class XPSHttpService {

  env: string;

  constructor(private http: HttpClient, store: Store) {
    store.select(selectEnvironment).pipe().subscribe(env => {
      if (environment.URL_SPECIAL_HANDLING === true && env === environment?.MAIN_ENV) {
        this.env = '';
        return;
      }
      this.env = env ? `.${env}` : '';
    });

  }

  get<T>(route: string, options?: Options): Observable<T> {
    return defer(() => this.httpFactory<T>('get', route, options));
  }

  put<T>(route: string, options?: Options): Observable<T> {
    return defer(() => this.httpFactory<T>('put', route, options));
  }

  post<T>(route: string, options?: Options): Observable<T> {
    return defer(() => this.httpFactory<T>('post', route, options));
  }

  private httpFactory<T>(method: Methods, route: string, options?: Options): Observable<T> {
    const session = Auth.currentSession();

    let baseUrl: string;
    if (environment.IS_LOCAL) {
      baseUrl = getEnvBaseUrl(route);
    } else {
      baseUrl = getCustomBaseUrl(route, this.env);
    }

    const httpOptions = {
      headers: {},
      params: {},
      resolveWithFullResponse: false,
    };

    if (typeof options !== 'undefined') {
      if (typeof options.params !== 'undefined') {
        httpOptions.params = options.params.get();
      }
      if (typeof options.headers !== 'undefined') {
        httpOptions.headers = options.headers;
      }
      if (typeof options.resolveWithFullResponse !== 'undefined' && options.resolveWithFullResponse) {
        httpOptions.resolveWithFullResponse = options.resolveWithFullResponse;
      }
    }

    const body = (options && options.json) || {};
    switch (method) {
    case 'put':
      if (httpOptions.resolveWithFullResponse) {
        return this.http.put<T>(baseUrl + route, body, {
          headers: httpOptions.headers,
          params: httpOptions.params,
          observe: 'response',
        }) as Observable<any>;
      }
      return this.http.put<T>(baseUrl + route, body, {
        headers: httpOptions.headers,
        params: httpOptions.params,
        observe: 'body',
      });
    case 'post':
      if (httpOptions.resolveWithFullResponse) {
        return this.http.post<T>(baseUrl + route, body, {
          headers: httpOptions.headers,
          params: httpOptions.params,
          observe: 'response',
        }) as Observable<any>;
      }
      return this.http.post<T>(baseUrl + route, body, {
        headers: httpOptions.headers,
        params: httpOptions.params,
        observe: 'body',
      });
    case 'del':
      if (httpOptions.resolveWithFullResponse) {
        return this.http.delete<T>(baseUrl + route, {
          headers: httpOptions.headers,
          params: httpOptions.params,
          observe: 'response',
        }) as Observable<any>;
      }
      return this.http.delete<T>(baseUrl + route, {
        headers: httpOptions.headers,
        params: httpOptions.params,
        observe: 'body',
      });
    default:
      if (httpOptions.resolveWithFullResponse) {
        return this.http.get<T>(baseUrl + route, {
          headers: httpOptions.headers,
          params: httpOptions.params,
          observe: 'response',
        }) as Observable<any>;
      }
      return this.http.get<T>(baseUrl + route, {
        headers: httpOptions.headers,
        params: httpOptions.params,
        observe: 'body',
      });
    }
  }
}
