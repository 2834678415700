// app/modules/shared/icons/icons.module.ts

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  adjustments,
  atSymbol,
  chevronDown,
  chevronUp,
  clipboardCopy,
  creditCard,
  documentSearch,
  fingerPrint,
  globe,
  HeroIconsModule,
  identification,
  key,
  library,
  mail,
  map,
  minusSm,
  officeBuilding,
  pencilSolid,
  phone,
  plusCircle,
  refresh,
  saveAs,
  sortDescending,
  table,
  template,
  user,
  users,
  viewGridAdd,
  viewList,
  x,
  xCircleSolid,
} from 'ng-heroicons';
@NgModule({
  declarations: [],
  imports: [
    HeroIconsModule.withIcons({
      adjustments,
      atSymbol,
      chevronDown,
      chevronUp,
      creditCard,
      clipboardCopy,
      documentSearch,
      fingerPrint,
      globe,
      identification,
      key,
      library,
      mail,
      map,
      minusSm,
      officeBuilding,
      pencilSolid,
      phone,
      plusCircle,
      refresh,
      saveAs,
      sortDescending,
      table,
      template,
      user,
      users,
      viewGridAdd,
      viewList,
      x,
      xCircleSolid,
    }),
    CommonModule,
  ],
})
export class IconsModule { }
