// app/modules/app-shell/app-shell.routing.ts

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../core/auth/auth.guard';
import { EnvGuard } from '../../core/auth/env.guard';
import { ContainerComponent } from './container/container.component';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const routes: Routes = [
  {
    path: 'authenticate',
    loadChildren: () => import('../authentication/authentication.module').then(module => module.AuthenticationModule),
  },
  {
    path: '',
    component: ContainerComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'api-keys',
        loadChildren: () => import('../core-services/api-keys/api-keys.module').then(module => module.ApiKeysModule),
        canActivate: [AuthGuard],
      },
      // {
      //   path: 'bureau-profile',
      //   loadChildren: () => import('../bureau-profile/bureau-profile.module').then(module => module.BureauProfileModule),
      //   canActivate: [AuthGuard],
      // },
      {
        path: 'dashboard',
        loadChildren: () => import('../dashboard/dashboard.module').then(module => module.DashboardModule),
        canActivate: [AuthGuard],
      },
      // {
      //   path: 'developers',
      //   loadChildren: () => import('../developers/developers.module').then(module => module.DevelopersModule),
      //   canActivate: [AuthGuard],
      // },
      // {
      //   path: 'invoices',
      //   loadChildren: () => import('../invoices/invoices.module').then(module => module.InvoicesModule),
      //   canActivate: [AuthGuard],
      // },
      {
        path: 'user-profile',
        loadChildren: () => import('../user-profile/user-profile.module').then(module => module.UserProfileModule),
        canActivate: [AuthGuard],
      },
      // {
      //   path: 'event-stream',
      //   loadChildren: () => import('../notification/event-stream/event-stream.module').then(module => module.EventStreamModule),
      //   canActivate: [AuthGuard],
      // },
      {
        path: 'web-hook',
        loadChildren: () => import('../notification/web-hooks/web-hooks.module').then(module => module.WebHooksModule),
        canActivate: [AuthGuard],
      },
      // {
      //   path: 'organization',
      //   loadChildren: () => import('../core-services/organization/organization.module').then(module => module.OrganizationModule),
      //   canActivate: [AuthGuard],
      // },
      // {
      //   path: 'programs',
      //   loadChildren: () => import('../core-services/programs/programs.module').then(module => module.ProgramsModule),
      //   canActivate: [AuthGuard],
      // },
      // {
      //   path: 'payments',
      //   loadChildren: () => import('../core-services/payments/payments.module').then(module => module.PaymentsModule),
      //   canActivate: [AuthGuard],
      // },
      // {
      //   path: 'ledger',
      //   loadChildren: () => import('../core-services/ledger/ledger.module').then(module => module.LedgerModule),
      //   canActivate: [AuthGuard],
      // },
      // {
      //   path: 'cards',
      //   loadChildren: () => import('../core-services/cards/cards.module').then(module => module.CardsModule),
      //   canActivate: [AuthGuard],
      // },
      {
        path: 'client',
        loadChildren: () => import('../core-services/client/client.module').then(module => module.ClientModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'individual',
        loadChildren: () => import('../core-services/individual/individual.module').then(module => module.IndividualModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'simulator',
        loadChildren: () => import('../core-services/simulator/simulator.module').then(module => module.SimulatorModule),
        canActivate: [AuthGuard, EnvGuard],
      },
      {
        path: 'reconciliation',
        loadChildren: () => import('../reconciliation/reconciliation.module').then(module => module.ReconciliationModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'wallet',
        loadChildren: () => import('../core-services/wallet/wallet.module').then(module => module.WalletModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'documents',
        loadChildren: () => import('../documents/documents.module').then(module => module.DocumentsModule),
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    // TODO switch back to /dashboard
    path: '**',
    redirectTo: '/dashboard',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {
        enableTracing: false,
        relativeLinkResolution: 'legacy',
        paramsInheritanceStrategy: 'always',
      },
    ),
  ],
  providers: [
    AuthGuard,
  ],
  exports: [RouterModule],
})
export class AppShellRoutingModule {
}
