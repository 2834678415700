// app/shared/directives/access.map.ts

/* eslint-disable @typescript-eslint/naming-convention */

export enum Roles {
  DEV_ADMIN = 'dev-admin',
  DEV_GENERAL = 'dev-general',
  DEV_OWNER = 'dev-owner',
  DEV_SUPPORT = 'dev-support',
  OPS_BIZ = 'ops-biz',
  OPS_CSR = 'ops-csr',
  OPS_DEV = 'ops-dev',
  OPS_FAC = 'ops-fac',
  OPS_FIN = 'ops-fin',
  OPS_VIEWER = 'ops-viewer',
}

// Edit = read-write, View = read-only
export enum RoleBasedPermission {
  EditAccessKey = 'EditAccessKey',
  EditDeveloper = 'EditDeveloper',
  EditProgram = 'EditProgram',
  EditWebHook = 'EditWebHook',
  ViewBureau= 'ViewBureau',
  ViewDeveloper= 'ViewDeveloper',
  ViewInvoice = 'ViewInvoice',
  ViewWebHook = 'ViewWebHook',
}

export const accessMap = {
  [RoleBasedPermission.EditAccessKey]: [Roles.DEV_ADMIN, Roles.DEV_OWNER],
  [RoleBasedPermission.EditDeveloper]: [Roles.DEV_ADMIN, Roles.DEV_OWNER],
  [RoleBasedPermission.EditProgram]: [Roles.DEV_ADMIN, Roles.DEV_GENERAL, Roles.DEV_OWNER],
  [RoleBasedPermission.EditWebHook]: [Roles.DEV_ADMIN, Roles.DEV_GENERAL, Roles.DEV_OWNER],
  [RoleBasedPermission.ViewBureau]: [Roles.DEV_ADMIN, Roles.DEV_OWNER, Roles.OPS_DEV],
  [RoleBasedPermission.ViewDeveloper]: [Roles.DEV_GENERAL, Roles.DEV_SUPPORT, Roles.OPS_DEV],
  [RoleBasedPermission.ViewInvoice]: [Roles.DEV_OWNER, Roles.OPS_DEV],
  [RoleBasedPermission.ViewWebHook]: [Roles.DEV_SUPPORT, Roles.OPS_DEV],
};
