// app/core/auth/auth.reducer.ts

import { Action, createReducer, on } from '@ngrx/store';
import { v1 as ApiDops } from '@xform/api-dops';
import { v1 as ApiFops } from '@xform/api-fops';
import { ModifyDeveloperEvent } from '@xform/api-dops/dist/api/v1';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import * as jwt from 'jsonwebtoken';
import { MFASetupResponse } from '../../modules/shared/services/cognito-service/cognito-auth.service';
import * as AuthActions from './auth.actions';

/* eslint-disable sonarjs/no-duplicate-string */

export interface State {
  user?: ApiDops.DeveloperView;
  userId?: string;
  loadingUser?: boolean;
  selectedAccessClaim?: string;
  selectedEnvironment?: string
  modifyUserEventId?: string;
  modifyUserEvent?: ModifyDeveloperEvent;
  userBureauId?: string;
  bureau?: ApiDops.BureauView;
  bureauLoading?: boolean;
  error?: ApiDops.Error;
  generalError?: ApiDops.Error;
  isAuthenticated?: boolean;
  isMFASetup?: boolean;
  loggingIn: boolean;
  challengeName?: string;
  mfaSetupResponse?: MFASetupResponse;
  signInLoading?: boolean;
  mfaAuthLoading?: boolean;
  jwt?: string;
  bureauList?: ApiFops.BureauList
}

const initialState: State = {
  loadingUser: false,
  isAuthenticated: false,
  loggingIn: false,
  isMFASetup: false,
};

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function getUserId(cognitoUserSession: CognitoUserSession): string {
  const developerId = cognitoUserSession.getIdToken().decodePayload()['custom:developerId'];
  const opsId = cognitoUserSession.getIdToken().decodePayload()['custom:opsId'];
  const token = cognitoUserSession.getIdToken().getJwtToken();

  const decoded = jwt.decode(token, { complete: true });

  const payload = decoded?.payload as jwt.JwtPayload;

  return typeof developerId !== 'undefined' ? developerId as string : opsId as string;
}

export const authReducer = createReducer(
  initialState,
  on(AuthActions.authError, (state, { error }) => ({
    ...state,
    error,
    isAuthenticated: false,
    loadingUser: false,
    loggingIn: false,
    signInLoading: false,
    mfaAuthLoading: false,
  })),
  on(AuthActions.generalError, (state, { generalError }) => ({
    ...state,
    generalError,
  })),
  on(AuthActions.clearAuthError, (state) => ({
    ...state,
    error: null,
  })),
  on(AuthActions.getOpsUserSuccess, (state, { opsUser }) => (
    {
      ...state,
      user: opsUser as any,
    }
  )),
  on(AuthActions.getUser, (state) => (
    {
      ...state,
      loadingUser: true,
    }),
  ),
  on(AuthActions.getUserSuccess, (state, { user }) => (
    {
      ...state,
      user,
      loadingUser: false,
      loggingIn: false,
    }),
  ),
  on(AuthActions.opsDevBureauSelected, (state, { bureau }) => (
    {
      ...state,
      bureau,
      userBureauId: bureau.bureauId,
    }
  ),
  ),
  on(AuthActions.getBureau, (state) => (
    {
      ...state,
      generalError: null,
      loading: true,
    }),
  ),
  on(AuthActions.getBureauSuccess, (state, { bureau }) => (
    {
      ...state,
      bureau,
      generalError: null,
      loading: false,
    }),
  ),
  on(AuthActions.getBureausForOpsDevSuccess, (state, { bureauList }) => (
    {
      ...state,
      bureauList,
    }),
  ),
  on(AuthActions.confirmSignIn, (state) => (
    {
      ...state,
      mfaAuthLoading: true,
    }),
  ),
  // TODO make sure amplify does null checks
  on(AuthActions.loginSuccess, (state, { cognitoUserSession, isMFASetup }) => ({
    ...state,
    jwt: cognitoUserSession.getIdToken().getJwtToken(),
    // eslint-disable-next-line sonarjs/no-duplicate-string
    userId: getUserId(cognitoUserSession),
    // eslint-disable-next-line sonarjs/no-duplicate-string
    userBureauId: cognitoUserSession.getIdToken().decodePayload()['custom:bureauId'],
    isAuthenticated: true,
    loggingIn: true,
    isMFASetup: Boolean(isMFASetup),
  })),
  on(AuthActions.confirmSignInSuccess, (state, { cognitoUserSession }) => ({
    ...state,
    jwt: cognitoUserSession.getIdToken().getJwtToken(),
    userId: getUserId(cognitoUserSession),
    userBureauId: cognitoUserSession.getIdToken().decodePayload()['custom:bureauId'],
    isAuthenticated: true,
    loggingIn: true,
    isMFASetup: true,
  })),
  on(AuthActions.modifyUser, (state, { modifyUserEventId, modifyUserEvent }) => ({
    ...state,
    modifyUserEventId,
    modifyUserEvent,
    loadingUser: true,
  })),
  on(AuthActions.signIn, (state) => (
    {
      ...state,
      signInLoading: true,
    }),
  ),
  on(AuthActions.modifyUserSuccess, (state) => ({
    ...state,
    modifyUserEventId: null,
    modifyUserEvent: null,
    error: null,
  })),
  on(AuthActions.updateAccessClaim, (state, { environment, access }) => ({
    ...state,
    selectedAccessClaim: access,
    selectedEnvironment: environment,
  })),
  on(AuthActions.updateEnvironment, (state, { instance }) => ({
    ...state,
    selectedEnvironment: instance,
  })),
  on(AuthActions.setAuthenticatedToFalse, () => ({
    ...initialState,
  })),
  on(AuthActions.setAuthenticatedToTrue, (state) => ({
    ...state,
    isAuthenticated: true,
  })),
  on(AuthActions.verifyMFATokenSuccess, (state, { cognitoUserSession }) => ({
    ...state,
    jwt: cognitoUserSession.getIdToken().getJwtToken(),
    userId: getUserId(cognitoUserSession),
    userBureauId: cognitoUserSession.getIdToken().decodePayload()['custom:bureauId'],
    isAuthenticated: true,
    isMFASetup: true,
    mfaAuthLoading: false,
  })),
  on(AuthActions.signInSuccess, (state, { challengeName }) => ({
    ...state,
    challengeName,
    signInLoading: false,
    error: null,
  }),
  ),
  on(AuthActions.setupMFASuccess, (state, { mfaSetupResponse }) => ({
    ...state,
    mfaSetupResponse,
  }),
  ),
);

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function reducers(state: State | undefined, action: Action): State {
  return authReducer(state, action);
}
