// app/core/auth/env.guard.ts

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, mapTo, tap } from 'rxjs/operators';
import { Environment } from '../../shared/ui-models/environment';
import { selectEnvironment } from './auth.selectors';

/**
 * Prevent access to routes if Environment is Production.
 *
 * @export
 * @class EnvGuard
 * @implements {CanActivate}
 */
@Injectable()
export class EnvGuard implements CanActivate {
  constructor(
    private store: Store,
    private router: Router,
  ) { }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.store.select(selectEnvironment).pipe(
      map(value => value !== Environment.Production),
      tap((value) => {
        if (value) {
          return true;
        }
      }),
      catchError(() => of(false)),
    );
  }
}

