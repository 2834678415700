// app/core/auth/auth.actions.ts

import { createAction, props } from '@ngrx/store';
import { v1 as ApiDops } from '@xform/api-dops';
import { v1 as ApiFops } from '@xform/api-fops';
import { DeveloperView, ModifyDeveloperEvent } from '@xform/api-dops/dist/api/v1/swagger';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { MFASetupResponse } from '../../modules/shared/services/cognito-service/cognito-auth.service';

export enum AuthActionsType {
  AuthError = '[Auth] Get Auth Error',
  SignIn = '[Auth] SignIn User',
  SignInSuccess = '[Auth] SignIn User Success',
  ConfirmSignIn = '[Auth] Confirm SignIn User',
  ConfirmSignInSuccess = '[Auth] Confirm SignIn User Success',
  ForgotPassword = '[Auth] Forgot Password',
  ForgotPasswordSuccess = '[Auth] Forgot Password Success',
  ConfirmForgotPassword = '[Auth] Confirm Forgot Password',
  ConfirmForgotPasswordSuccess = '[Auth] Confirm Forgot Password Success',
  SetupPassword = '[Auth] Setup Password',
  SetupPasswordSuccess = '[Auth] Setup Password Success',
  ChangePassword = '[Auth] Change Password',
  ChangePasswordSuccess = '[Auth] Change Password Success',
  ClearAuthError = '[Auth] Clear Auth Error',
  GetUser = '[Auth] Get User',
  GetUserSuccess = '[Auth] Get User Success',
  GetBureau = '[Auth] Get Bureau',
  GetBureauSuccess = '[Auth] Get Bureau Success',
  GetOpsUser = '[Auth] Get Ops User',
  GetOpsUserSucces = '[Auth] Get Ops User Success',
  GetBureausForOpsDev = '[Auth] Get Bureaus For Dev Ops Dev',
  GetBureausForOpsDevSuccess = '[Auth] Get Bureaus For Dev Ops Dev Success',
  OpsDevBureauSelected = '[Auth] Ops Dev Bureau Selected',
  // OpsDevBureauSelectedSuccess = '[Auth] Ops Dev Bureau Selected Success',
  GeneralError = '[Auth] General Error',
  LoginSuccess = '[Auth] Login Success',
  LoginRedirect = '[Auth] Login Redirect',
  MFARedirect = '[Auth] MFA Redirect',
  ModifyUser = '[Auth] Modify User',
  ModifyUserSuccess = '[Auth] Modify User Success',
  UpdateAccessClaim = '[Auth] Update Access Claim',
  UpdateEnvironment = '[Auth] Update Environment Claim',
  AuthenticatedTrue = '[Auth] Authenticated Set To True',
  AuthenticatedFalse = '[Auth] Authenticated Set To False',
  SetupMFA = '[Auth] Setup MFA',
  SetupMFASuccess = '[Auth] Setup MFA Success',
  VerifyMFAToken = '[Auth] Verify MFA Token',
  VerifyMFATokenSuccess = '[Auth] Verify MFA Token Success',
}

export const authError = createAction(AuthActionsType.AuthError, props<{ error: ApiDops.Error }>());
export const generalError = createAction(AuthActionsType.GeneralError, props<{ generalError: ApiDops.Error }>());
export const signIn = createAction(AuthActionsType.SignIn, props<{ userEmail: string, password: string }>());
export const signInSuccess = createAction(AuthActionsType.SignInSuccess, props<{ challengeName: string }>());
export const confirmSignIn = createAction(AuthActionsType.ConfirmSignIn, props<{ code: string }>());
export const confirmSignInSuccess = createAction(AuthActionsType.ConfirmSignInSuccess, props<{ cognitoUserSession: CognitoUserSession }>());
export const clearAuthError = createAction(AuthActionsType.ClearAuthError);
export const forgotPassword = createAction(AuthActionsType.ForgotPassword, props<{ userEmail: string }>());
export const forgotPasswordSuccess = createAction(AuthActionsType.ForgotPasswordSuccess);
export const confirmForgotPassword = createAction(AuthActionsType.ConfirmForgotPassword, props<{ userEmail: string, code: string, password: string }>());
export const confirmForgotPasswordSuccess = createAction(AuthActionsType.ConfirmForgotPasswordSuccess);
export const getBureau = createAction(AuthActionsType.GetBureau, props<{ cognitoUserSession: CognitoUserSession }>());
export const getBureauSuccess = createAction(AuthActionsType.GetBureauSuccess, props<{ bureau: ApiDops.BureauView }>());
export const getBureausForOpsDev = createAction(AuthActionsType.GetBureausForOpsDev, props<{ jwt: string }>());
export const opsDevBureauSelected = createAction(AuthActionsType.OpsDevBureauSelected, props<{ bureau: ApiDops.BureauView }>());
export const getOpsUser = createAction(AuthActionsType.GetOpsUser);
export const getOpsUserSuccess = createAction(AuthActionsType.GetOpsUserSucces, props<{opsUser: ApiFops.OpsUserView}>());

// export const opsDevBureauSelectedSuccess = createAction(AuthActionsType.OpsDevBureauSelectedSuccess);
export const getBureausForOpsDevSuccess = createAction(AuthActionsType.GetBureausForOpsDevSuccess, props<{ bureauList: ApiFops.BureauList }>());
export const setupPassword = createAction(AuthActionsType.SetupPassword, props<{ password: string }>());
export const setupPasswordSuccess = createAction(AuthActionsType.SetupPasswordSuccess);
export const changePassword = createAction(AuthActionsType.ChangePassword, props<{ oldPassword: string, newPassword: string }>());
export const changePasswordSuccess = createAction(AuthActionsType.ChangePasswordSuccess);
export const getUser = createAction(AuthActionsType.GetUser, props<{ userId: string }>());
export const getUserSuccess = createAction(AuthActionsType.GetUserSuccess, props<{ user: DeveloperView }>());
export const loginSuccess = createAction(AuthActionsType.LoginSuccess, props<{ cognitoUserSession: CognitoUserSession, isMFASetup: boolean }>());
export const modifyUser = createAction(AuthActionsType.ModifyUser, props<{ modifyUserEventId: string, modifyUserEvent: ModifyDeveloperEvent }>());
export const modifyUserSuccess = createAction(AuthActionsType.ModifyUserSuccess);
export const updateAccessClaim = createAction(AuthActionsType.UpdateAccessClaim, props<{ environment: string, access: string }>());
export const updateEnvironment = createAction(AuthActionsType.UpdateEnvironment, props<{ instance: string }>());
export const setAuthenticatedToTrue = createAction(AuthActionsType.AuthenticatedTrue);
export const setAuthenticatedToFalse = createAction(AuthActionsType.AuthenticatedFalse);
export const loginRedirect = createAction(AuthActionsType.LoginRedirect);
export const mfaRedirect = createAction(AuthActionsType.MFARedirect);
export const setupMFA = createAction(AuthActionsType.SetupMFA);
export const setupMFASuccess = createAction(AuthActionsType.SetupMFASuccess, props<{ mfaSetupResponse: MFASetupResponse }>());
export const verifyMFAToken = createAction(AuthActionsType.VerifyMFAToken, props<{ challenge: string }>());
export const verifyMFATokenSuccess = createAction(AuthActionsType.VerifyMFATokenSuccess, props<{ cognitoUserSession: CognitoUserSession }>());
