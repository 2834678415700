// app/core/auth/jwt.interceptor.ts

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Auth } from '@aws-amplify/auth';
import { combineLatest, from, Observable } from 'rxjs';
import { catchError, first, switchMap } from 'rxjs/operators';
import { selectUserBureauId } from './auth.selectors';
import { getAccessClaims, isOpsDev } from './access-claim.util';

/**
 * This will append jwt token for the http requests.
 *
 * @export
 * @class JwtInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private store: Store,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return combineLatest([
      from(Auth.currentSession()),
      this.store.select(selectUserBureauId).pipe(
        first(),
      ),
    ])
      .pipe(
        switchMap(([auth, userBureauId]) => { // switchMap() is used instead of map().

          const jwt = auth?.getIdToken()?.getJwtToken();

          const claims = getAccessClaims(jwt);

          let bureauId = isOpsDev(claims) ? userBureauId : auth.getIdToken().decodePayload()['custom:bureauId'];

          if (request.url.includes('/api-fops/v1/view/bureau')) {
            bureauId = auth.getIdToken().decodePayload()['custom:bureauId'];
          }

          const headers = {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            Authorization: `Bearer ${jwt}`,
          };

          if (userBureauId) {
            headers['x-bureau-id'] = userBureauId;
          }

          let req = request;

          // Do not attach jwtToken if uploading to S3
          if (req.url !== 'https://s3.amazonaws.com/xps-portal-test-bucket') {
            req = request.clone({
              setHeaders: headers,
            });
          }

          return next.handle(req);
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }
}
