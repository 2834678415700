// app/core/auth/auth.effects.ts

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { selectUserId } from '@app/core/auth/auth.selectors';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mapTo, mergeMap, shareReplay, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { BureauService } from '../../modules/bureau-profile/bureau.service';
import { CognitoAuthService } from '../../modules/shared/services/cognito-service/cognito-auth.service';
import { DeveloperService } from './../../modules/developers/developer.service';
import { canAccessBureau, getAccessClaims, isOpsDev } from './access-claim.util';
import {
  authError,
  changePassword,
  changePasswordSuccess,
  confirmForgotPassword,
  confirmForgotPasswordSuccess,
  confirmSignIn,
  confirmSignInSuccess,
  forgotPassword,
  forgotPasswordSuccess,
  generalError,
  getBureau,
  getBureausForOpsDev,
  getBureausForOpsDevSuccess,
  getBureauSuccess,
  getOpsUser,
  getOpsUserSuccess,
  getUser,
  getUserSuccess,
  loginRedirect,
  loginSuccess,
  mfaRedirect,
  modifyUser,
  modifyUserSuccess,
  opsDevBureauSelected,
  setAuthenticatedToFalse,
  setupMFA,
  setupMFASuccess,
  setupPassword,
  setupPasswordSuccess,
  signIn,
  signInSuccess,
  verifyMFAToken,
  verifyMFATokenSuccess,
} from './auth.actions';
import { State } from './auth.reducer';
import { FopsService } from './fops.service';

/* eslint-disable sonarjs/no-duplicate-string */

@Injectable()
export class AuthEffects {

  changePassword$ = createEffect(() => this.actions$.pipe(
    ofType(changePassword),
    mergeMap((action) => this.cognitoAuthService.changePassword(action.oldPassword, action.newPassword).pipe(
      mapTo(changePasswordSuccess()),
      catchError(error => of(authError({ error }))),
    )),
  ));

  signIn$ = createEffect(() => this.actions$.pipe(
    ofType(signIn),
    mergeMap((action) => this.cognitoAuthService.signIn(action.userEmail, action.password).pipe(
      map((challengeName) => signInSuccess({ challengeName })),
      catchError(error => of(authError({ error }))),
    )),
  ));

  confirmSignIn$ = createEffect(() => this.actions$.pipe(
    ofType(confirmSignIn),
    mergeMap((action) => this.cognitoAuthService.confirmSignIn(action.code).pipe(
      switchMap((cognitoUserSession) => {
        let acts: any[] = [ 
          confirmSignInSuccess({ cognitoUserSession }),
        ];

        const jwt = cognitoUserSession?.getIdToken()?.getJwtToken();

        const claims = getAccessClaims(jwt);

        if (!isOpsDev(claims)) {
          acts = [
            ...acts,
            getBureau({ cognitoUserSession }),
            getUser({ userId: cognitoUserSession.getIdToken().decodePayload()['custom:developerId'] }),
          ];
        }
        return acts;
      }),
      catchError(error => of(authError({ error }))),
    )),
  ));

  forgetPassword$ = createEffect(() => this.actions$.pipe(
    ofType(forgotPassword),
    mergeMap((action) => this.cognitoAuthService.forgotPassword(action.userEmail).pipe(
      mapTo(forgotPasswordSuccess()),
      catchError(error => of(authError({ error }))),
    )),
  ));

  confirmForgetPassword$ = createEffect(() => this.actions$.pipe(
    ofType(confirmForgotPassword),
    mergeMap((action) => this.cognitoAuthService.confirmForgotPassword(action.userEmail, action.code, action.password).pipe(
      mapTo(confirmForgotPasswordSuccess()),
      catchError(error => of(authError({ error }))),
    )),
  ));

  setupPassword$ = createEffect(() => this.actions$.pipe(
    ofType(setupPassword),
    mergeMap((action) => this.cognitoAuthService.setupPassword(action.password).pipe(
      mapTo(setupPasswordSuccess()),
      catchError(error => of(authError({ error }))),
    )),
  ));

  getBureauForOpsDev$ = createEffect(() => this.actions$.pipe(
    ofType(getBureausForOpsDev),
    mergeMap((action) => {
      // check access claim
      const jwt = action.jwt;

      const claims = getAccessClaims(jwt);

      if (!isOpsDev(claims)) {
        return of({type:'NO_ACTION'});
      }

      // check if we should get claims
      return this.bureauService.getBureauList().pipe(
        map((bureauList) => getBureausForOpsDevSuccess({ bureauList })),
        catchError(error => of(generalError({ generalError: error }))),
      );
    }),
  ));

  getUser$ = createEffect(() => this.actions$.pipe(
    ofType(getUser),
    mergeMap((action) => this.developerService.getDeveloper(action.userId).pipe(
      map((user) => getUserSuccess({ user })),
      catchError(error => of(generalError({ generalError: error }))),
    )),
  ));

  getBureau$ = createEffect(() => this.actions$.pipe(
    ofType(getBureau),
    mergeMap((action) => { 
      const jwt = action.cognitoUserSession.getIdToken().getJwtToken();

      let defaultClaim;
      const claims = getAccessClaims(jwt);

      // eslint-disable-next-line guard-for-in
      for (const envKey in claims) {
        const claim = claims[envKey];
        if (!defaultClaim) {
          defaultClaim = claim;
        }
      }
  
      const bureauId =  action.cognitoUserSession.getIdToken().decodePayload()['custom:bureauId'];

      if (canAccessBureau(defaultClaim || '')) {
        return this.bureauService.getBureau(bureauId).pipe(
          map((bureau) => getBureauSuccess({ bureau })),
          catchError(error => of(generalError({ generalError: error }))),
        );
      }
      return of({type:'NO_ACTION'});
    }),
  ));

  modifyUser$ = createEffect(() => this.actions$.pipe(
    ofType(modifyUser),
    mergeMap((action) => this.developerService.modifyDeveloper(action.modifyUserEventId, action.modifyUserEvent).pipe(
      withLatestFrom(this.store.select(selectUserId)),
      switchMap(([, userId]) => [
        modifyUserSuccess(),
        getUser({ userId }),
      ]),
      catchError(error => of(authError({ error }))),
    )),
  ));

  loginRedirect$ = createEffect(() => this.actions$.pipe(
    ofType(loginRedirect, setAuthenticatedToFalse),
    tap(() => {
      this.router.navigate(['/authenticate/sign-in'], { replaceUrl: true });
    }),
  ), { dispatch: false },
  );

  mfaRedirect$ = createEffect(() => this.actions$.pipe(
    ofType(mfaRedirect),
    tap(() => {
      this.router.navigate(['authenticate/mfasetup'], { replaceUrl: true });
    }),
  ), { dispatch: false },
  );

  loginSuccess$ = createEffect(() => this.actions$.pipe(
    shareReplay(),
    ofType(loginSuccess),
    switchMap((action) => [
      getBureau({ cognitoUserSession: action.cognitoUserSession }),
      getUser({ userId: action.cognitoUserSession.getIdToken().decodePayload()['custom:developerId'] }),
    ]),
    catchError(error => of(authError({ error: error.error }))),
  ));

  mfaSetup$ = createEffect(() => this.actions$.pipe(
    ofType(setupMFA),
    mergeMap((action) => this.cognitoAuthService.setupMFA().pipe(
      map((mfaSetupResponse) => setupMFASuccess({ mfaSetupResponse })),
      catchError(error => of(authError({ error }))),
    )),
  ));

  opsDevSelectedBureau$ = createEffect(() => this.actions$.pipe(
    ofType(opsDevBureauSelected),
    tap(() => {
      this.router.navigate(['/dashboard']);
    }),   
  ), { dispatch: false },
  );

  getOpsUser$ = createEffect(() => this.actions$.pipe(
    ofType(getOpsUser),
    withLatestFrom(this.store.select(selectUserId)),
    switchMap(([bureau, userId]) => this.fopsService.getOpsUser(userId).pipe(
      map((opsUser) => getOpsUserSuccess({ opsUser })),
    )),
  ))

  verifyMFAToken$ = createEffect(() => this.actions$.pipe(
    ofType(verifyMFAToken),
    mergeMap((action) => this.cognitoAuthService.verifyMFAToken(action.challenge).pipe(
      switchMap((cognitoUserSession) => [
        verifyMFATokenSuccess({ cognitoUserSession }),
        getBureau({ cognitoUserSession }),
        getUser({ userId: cognitoUserSession.getIdToken().decodePayload()['custom:developerId'] }),
      ]),
      catchError(error => of(authError({ error }))),
    )),
  ));

  constructor(
    private actions$: Actions,
    private cognitoAuthService: CognitoAuthService,
    private bureauService: BureauService,
    private developerService: DeveloperService,
    private fopsService: FopsService,
    private store: Store<State>,
    private router: Router,
  ) {
  }
}
