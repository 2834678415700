// app/modules/shared/services/cognito-service/cognito-auth.service.ts

/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */

/* eslint-disable @typescript-eslint/restrict-template-expressions */

import { Injectable } from '@angular/core';
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { Auth } from '@aws-amplify/auth';
import { from, Observable } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ErrorHandlingService } from '@xform/xformative-angular';

export enum ChallengeName {
  SoftwareTokenMFA = 'SOFTWARE_TOKEN_MFA',
  NewPasswordRequired = 'NEW_PASSWORD_REQUIRED',
  MfaSetup = 'MFA_SETUP',
  NoMFA = 'NOMFA',
}

export interface MFASetupResponse {
  qrCodeUrl: string,
  mfaCode: string,
}

@Injectable({
  providedIn: 'root',
})
export class CognitoAuthService {

  private user: CognitoUser;

  constructor(
    private errorHandlingService: ErrorHandlingService,
  ) {
  }

  setCurrentCognitoUser(user: CognitoUser): void {
    this.user = user;
  }

  getCurrentCognitoUser(): CognitoUser {
    return this.user;
  }

  currentAuthenticatedUser(): Observable<CognitoUser | any> {
    return from(Auth.currentAuthenticatedUser()).pipe(
      catchError(this.errorHandlingService.rxjsErrorHandler()),
    );
  }

  changePassword(oldPassword: string, newPassword: string): Observable<string> {
    return this.currentAuthenticatedUser().pipe(
      switchMap((user) => from(Auth.changePassword(user, oldPassword, newPassword)).pipe(
        catchError(this.errorHandlingService.rxjsErrorHandler()),
      )),
    );
  }

  signIn(userEmail: string, password: string): Observable<string> {
    return from(Auth.signIn(userEmail, password)).pipe(
      map(user => {
        this.setCurrentCognitoUser(user);
        return (typeof user.challengeName === 'undefined' && user.preferredMFA === ChallengeName.NoMFA) ? ChallengeName.NoMFA : user.challengeName;
      }),
    );
  }

  confirmSignIn(code: string): Observable<CognitoUserSession> {
    return from(Auth.confirmSignIn(this.user, code, ChallengeName.SoftwareTokenMFA)).pipe(
      map((user) => user.getSignInUserSession()),
    );
  }

  // ignore, no logic
  /* istanbul ignore next */
  setupPassword(password: string): Observable<void> {
    return from(Auth.completeNewPassword(this.user, password));
  }

  // ignore, no logic
  /* istanbul ignore next */
  forgotPassword(userEmail: string): Observable<void> {
    return from(Auth.forgotPassword(userEmail));
  }

  // ignore, no logic
  /* istanbul ignore next */
  confirmForgotPassword(userEmail: string, code: string, newPassword: string): Observable<void> {
    return from(Auth.forgotPasswordSubmit(userEmail, code, newPassword));
  }

  setupMFA(): Observable<MFASetupResponse> {
    return this.currentAuthenticatedUser().pipe(
      switchMap((user) => from(Auth.setupTOTP(user).then((code) => ({
        qrCodeUrl: `otpauth://totp/Xformative:${(user as any).attributes.email}?secret=${code}&issuer=Xformative`,
        mfaCode: code,
      } as MFASetupResponse),
      ))),
    );
  }

  verifyMFAToken(challenge: string): Observable<CognitoUserSession> {
    return this.currentAuthenticatedUser().pipe(
      switchMap((user) => from(
        Auth.verifyTotpToken(user, challenge).then(
          (cognitoUserSession) => Auth.setPreferredMFA(user, 'TOTP').then(
            () => user.getSignInUserSession())),
      )),
    );
  }
}
